import type { AxiosPromise, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import type { CategoryTip, CategoryTipResponse } from '@model/category/CategoryTip.js'
import type { ProductSearchResult } from '@model/product'
import { KK_POST_ENDPOINT, KK_STORE } from './apiConfig'
import type { Facet } from '@model/category/Facet'
import type { KKError } from '@model/konakart/KKError'
import type { ProductSearchFilter } from '@model/product/ProductSearchResult'
import type { SWRResponse } from 'swr'
import useSWRImmutable from 'swr/immutable'
import { logError } from '@utils/errors-client'
import type { StringResponse, TagGroup } from '@model/misc/TagGroup'
import { emptyOrNil, getNumbersWithConstraints, getTagGroupsForSearch } from '@utils/product/old-price-utils'
import { pick } from 'ramda'
import { makeAxiosConfig } from '@utils/axios-utils'
import { getData, setData } from '@api/redis'
import type { LanguageId } from '@model/locales'
import type { Catalog } from '@model/pricelist/Catalog'

export const searchRule = {
  SEARCH_ADD_WILDCARD_AFTER: 2,
  SEARCH_ADD_WILDCARD_BEFORE: 1,
  SEARCH_ADD_WILDCARD_BEFORE_AND_AFTER: 3,
  SEARCH_EXACT: 0,
} as const

export const mainCategoryId = KK_STORE === 'store1' ? 177 : 191
const collectionsCatId = KK_STORE === 'store1' ? 177 : 1141

const tagsTransform = pick(['facetConstraint', 'facetConstraints', 'facetNumber', 'id', 'name'])

type SearchResultResponse = {
  r: ProductSearchResult
} & KKError

export const collectionsData = {
  'recommendations-c': {
    categoryId: mainCategoryId,
    facets: [] as Facet[],
    custom: {},
    order: '',
  },
  'monthly-offer-c': {
    categoryId: mainCategoryId,
    facets: [] as Facet[],
    custom: {
      custom1: 'preview:monthly-offer',
      custom2: 'monthly-offer-shop',
    },
    order: '',
  },
  'new-c': {
    categoryId: mainCategoryId,
    facets: [
      {
        facetConstraint: KK_STORE === 'store1' ? 'facet.web.label.new' : 'facet.label.new',
        facetNumber: KK_STORE === 'store1' ? 16 : 9,
      },
    ] as Facet[],
    custom: {
      custom1: 'preview:new',
      custom2: 'new-shop',
    },
    order: '',
  },
  'best-sellers-c': {
    categoryId: mainCategoryId,
    facets: [
      {
        facetConstraint: 'facet.web.label.most-wanted',
        facetNumber: 99,
      },
    ] as Facet[],
    custom: {
      custom1: 'preview:most-wanted',
      custom2: 'best-sellers-shop',
    },
    order: 'ORDER_BY_TIMES_ORDERED_DESCENDING',
  },
  'beverages-c': {
    categoryId: collectionsCatId,
    facets: [] as Facet[],
    custom: {
      custom1: 'preview:beverages',
    },
    order: '',
  },
  'campaign-products-c': {
    categoryId: collectionsCatId,
    facets: [] as Facet[],
    custom: {
      custom1: 'preview:campaign',
    },
    order: '',
  },
  'club-one-c': {
    categoryId: collectionsCatId,
    facets: [
      {
        facetConstraint: 'facet.label.club.one',
        facetNumber: 9,
      },
    ] as Facet[],
    custom: {
      custom1: 'preview:club-one',
    },
    order: '',
  },
  'on-sale-c': {
    categoryId: collectionsCatId,
    facets: [
      {
        facetConstraint: 'facet.campaign.extra',
        facetNumber: 5,
      },
    ] as Facet[],
    custom: {
      custom1: 'preview:on-sale',
    },
    order: '',
  },
  'travel-retail-exclusive-c': {
    categoryId: collectionsCatId,
    facets: [
      {
        facetConstraint: 'facet.web.label.travel.retail.exclusive',
        facetNumber: 16,
      },
    ] as Facet[],
    custom: {
      custom2: 'travel-retail-exclusive-mystar',
    },
    order: '',
  },
} as const

export type CollectionType = keyof typeof collectionsData

export const getCategoryTree = async (
  languageId = 5,
  catalogId: Catalog,
  sessionId?: string
): Promise<CategoryTip[]> => {
  const input2Obj = {
    catalogId,
    getProductCounts: true,
    languageId: `${languageId}`,
    ...(sessionId && { sessionId }),
  }

  // const cKey = `getCategoryTree-${languageId}`
  // if (!sessionId) {
  //   const cached = await getData<CategoryTip[]>(cKey)
  //   if (cached) {
  //     return cached
  //   }
  // }

  const config = makeAxiosConfig({
    f: 'custom',
    s: KK_STORE,
    languageId,
    input1: 'getCategoryTreeWithOptionsCustom',
    input2: JSON.stringify(input2Obj, null, 0),
  })

  try {
    const { data } = await (axios(config) as AxiosPromise<CategoryTipResponse>)
    const { r } = data
    return JSON.parse(r) as CategoryTip[]
  } catch (e) {
    logError(`Error in getCategoryTree`, e)
    return [] as CategoryTip[]
  }
}

export const getTagGroups = async (languageId = 5): Promise<TagGroup[]> => {
  const cKey = `getTagGroups-${KK_STORE}-${languageId}`
  const cached = await getData<TagGroup[]>(cKey)

  if (cached) {
    return cached
  }

  const config: AxiosRequestConfig = {
    method: 'post',
    url: KK_POST_ENDPOINT,
    data: {
      f: 'custom',
      s: KK_STORE,
      languageId,
      input1: 'getAllTagGroupsWithTags',
      input2: languageId,
      method: 'jsonp',
    },
    headers: {
      'Content-Type': 'application/json; charset=utf-8;',
    },
  }

  try {
    const { data } = await (axios(config) as AxiosPromise<StringResponse>)
    const { r } = data
    const result = JSON.parse(r) as TagGroup[]
    if (result && result.length) await setData(cKey, result)
    return result
  } catch (e) {
    logError(`Error in getCategoryTree`, e)
    return [] as TagGroup[]
  }
}

export const getCategoryProductsResult = async (
  filters: ProductSearchFilter = { languageId: 1, catalogId: 'shop' },
  limit = 30,
  offset = 0,
  allTagGroups: TagGroup[] = [],
  sessionId?: string
): Promise<ProductSearchResult | null> => {
  const { languageId, categoryId, tagGroups = [], ...restFilters } = filters

  let tagGroupsToSend: TagGroup[] = allTagGroups
  if (!emptyOrNil(tagGroups)) {
    const filledTagGroups = tagGroups.map((grp) => {
      const full = allTagGroups.find(({ facetNumber }) => facetNumber === grp.facetNumber)
      return {
        ...full,
        ...grp,
      }
    })

    const facetNumbersWithConstraints: number[] = getNumbersWithConstraints(tagGroups) as number[]
    const restTagGroups = allTagGroups.filter(({ facetNumber }) => !facetNumbersWithConstraints.includes(facetNumber))
    tagGroupsToSend = [...(getTagGroupsForSearch(allTagGroups)(filledTagGroups) as TagGroup[]), ...restTagGroups]
  }

  const searchFilters: ProductSearchFilter = Object.assign(
    {},
    {
      searchTextRule: searchRule.SEARCH_EXACT,
      categoryIds: [],
      manufacturerIds: [],
      forceUseSolr: true,
      returnSearchScore: true,
      tagGroups: tagGroupsToSend.map(tagsTransform),
      returnCustomFacets: true,
      CustomFacets: true,
      fillDescription: true,
      returnCategoryFacets: true,
      returnManufacturerFacets: true,
      whereToSearch: -99,
      tokenizeSolrInput: true,
      languageId,
      categoryId,
    },
    restFilters as any
  )

  const requestData = {
    f: 'searchForProductsWithOptions',
    s: KK_STORE,
    languageId,
    ...(sessionId && { sessionId }),
    dataDesc: {
      offset,
      limit,
      fillCustomAttrArray: true,
      ...(filters.orderBy && { orderBy: filters.orderBy }),
    },
    prodSearch: searchFilters,
    options: {
      catalogId: filters.catalogId,
      getTags: true,
      custom1: 'setMinMaxPricesForWholeRange',
      custom4: 'EUR',
      ...(KK_STORE === 'store1' ? { useExternalQuantity: true } : {}),
    },
  }

  // console.log('--------------')
  // console.log(JSON.stringify(requestData, null, 2))
  // console.log('--------------')

  const config: AxiosRequestConfig = {
    method: 'post',
    timeout: 15000,
    url: KK_POST_ENDPOINT,
    data: requestData,
    headers: {
      'Content-Type': 'application/json; charset=utf-8;',
    },
  }

  try {
    const { data } = await (axios(config) as AxiosPromise<SearchResultResponse>)

    if (data.e && !data.r) {
      throw new Error(`${data.m}, ${data.e}`)
    }

    return data.r
  } catch (e) {
    const errorMessage = `Error loading searchForProductsWithOptions for category id ${categoryId}'`
    logError(errorMessage, e)
    return null
  }
}

// '245153,245146,219818'
export const getProductsBySkuList = async (
  skuList: string,
  languageId: LanguageId
): Promise<ProductSearchResult | null> => {
  const body = {
    f: 'searchForProductsWithOptions',
    s: KK_STORE,
    languageId,
    dataDesc: {
      offset: 0,
      limit: 25,
      fillCustomAttrArray: false,
    },
    prodSearch: {
      searchTextRule: searchRule.SEARCH_EXACT,
      searchText: skuList,
      categoryIds: [],
      manufacturerIds: [],
      forceUseSolr: true,
      returnSearchScore: false,
      tagGroups: [],
      whereToSearch: -99,
      tokenizeSolrInput: false,
    },
    options: {
      catalogId: 'Shop',
      getTags: false,
    },
  }

  const cKey = `getProductsBySkuList-${skuList}-${languageId}`
  const cached = await getData<ProductSearchResult>(cKey)

  if (cached) {
    return cached
  }

  const config: AxiosRequestConfig = {
    method: 'post',
    timeout: 15000,
    url: KK_POST_ENDPOINT,
    data: body,
    headers: {
      'Content-Type': 'application/json; charset=utf-8;',
    },
  }

  try {
    const { data } = await (axios(config) as AxiosPromise<SearchResultResponse>)

    if (data.e && !data.r) {
      throw new Error(`${data.m}, ${data.e}`)
    }

    await setData(cKey, data.r)
    return data.r
  } catch (e) {
    logError(`Error in getProductsBySkuList`, e)
    return null
  }
}

export interface ProductsCollectionOptions {
  custom1: string
  custom2: string
  catalogId?: Catalog
}

export const getProductsCollection = async (
  filters: ProductSearchFilter,
  facets: Facet[],
  { custom1, custom2, catalogId = 'shop' }: ProductsCollectionOptions,
  { offset, limit }: { offset: number; limit: number }
): Promise<ProductSearchResult | null> => {
  const allTagGroups = (await getTagGroups(filters.languageId)).map(tagsTransform)

  const tagGroups = allTagGroups.map((tag) => {
    const currentFacets = facets.filter((facet) => facet.facetNumber === tag.facetNumber)

    if (currentFacets.length) {
      const facets = currentFacets.reduce((acc, val) => {
        return { ...acc, ...val }
      }, {})
      return { ...tag, ...facets }
    } else {
      return tag
    }
  })

  const body = {
    f: 'searchForProductsWithOptions',
    s: KK_STORE,
    languageId: filters.languageId,
    dataDesc: {
      offset,
      limit,
      fillCustomAttrArray: true,
      ...(filters.orderBy && { orderBy: filters.orderBy }),
    },
    prodSearch: {
      searchTextRule: searchRule.SEARCH_EXACT,
      categoryIds: [],
      categoryId: filters.categoryId || mainCategoryId,
      manufacturerIds: filters.manufacturerIds || [],
      forceUseSolr: true,
      returnSearchScore: true,
      tagGroups,
      returnCustomFacets: limit > 4,
      fillDescription: true,
      returnCategoryFacets: limit > 4,
      returnManufacturerFacets: limit > 4,
      whereToSearch: -99,
      tokenizeSolrInput: false,
    },
    options: {
      catalogId,
      getTags: false,
      ...(custom1 && limit === 4 && { custom1 }),
      ...(custom2 && { custom2 }),
      custom4: 'EUR',
    },
  }

  // console.log('------------------ >')
  // console.log(JSON.stringify(body, null, 2))
  // console.log('< ------------------')

  const config: AxiosRequestConfig = {
    method: 'post',
    timeout: 15000,
    url: KK_POST_ENDPOINT,
    data: body,
    headers: {
      'Content-Type': 'application/json; charset=utf-8;',
    },
  }

  try {
    const { data } = await (axios(config) as AxiosPromise<SearchResultResponse>)

    if (data.e && !data.r) {
      throw new Error(`${data.m}, ${data.e}`)
    }

    return data.r
  } catch (e) {
    logError(`Error in getProductsCollection`, e)
    return null
  }
}

export const searchForProductsWithOptions = async (
  bodyData: ProductSearchFilter,
  limit = 30,
  offset = 0
): Promise<ProductSearchResult | null> => {
  const allTagGroups = await getTagGroups(bodyData.languageId)
  let { tagGroups } = bodyData

  if (typeof tagGroups === 'undefined' || tagGroups.length < 1) {
    tagGroups = allTagGroups.map(tagsTransform)
  }

  const body = {
    f: 'searchForProductsWithOptions',
    s: KK_STORE,
    languageId: bodyData.languageId,
    dataDesc: {
      offset: bodyData.offset || offset,
      limit: bodyData.limit || limit,
      fillCustomAttrArray: true,
      ...(bodyData.orderBy && { orderBy: bodyData.orderBy }),
    },
    prodSearch: {
      searchTextRule: searchRule.SEARCH_EXACT,
      categoryIds: bodyData.categoryIds || [],
      categoryId: bodyData.categoryId || mainCategoryId,
      manufacturerIds: bodyData.manufacturerIds || [],
      forceUseSolr: true,
      returnSearchScore: true,
      tagGroups,
      returnCustomFacets: true,
      fillDescription: true,
      returnCategoryFacets: true,
      returnManufacturerFacets: true,
      whereToSearch: -99,
      tokenizeSolrInput: true,
      ...(bodyData.priceFrom && { priceFrom: bodyData.priceFrom }),
      ...(bodyData.priceTo && { priceTo: bodyData.priceTo }),
      ...(bodyData.searchText && { searchText: bodyData.searchText.toLowerCase() }),
    },
    options: {
      catalogId: bodyData.catalogId || 'shop',
      getTags: true,
      custom1: 'setMinMaxPricesForWholeRange',
      custom4: 'EUR',
    },
    market: 'et',
  }

  const config: AxiosRequestConfig = {
    method: 'post',
    timeout: 15000,
    url: KK_POST_ENDPOINT,
    data: body,
    headers: {
      'Content-Type': 'application/json; charset=utf-8;',
    },
  }

  try {
    const { data } = await (axios(config) as AxiosPromise<SearchResultResponse>)
    if (data.e && !data.r) {
      throw new Error(`${data.m}, ${data.e}`)
    }
    return data.r
  } catch (e) {
    logError(`Error in searchForProductsWithOptions`, e)
    throw e
  }
}

export const getLevel1Categories = async (languageId: number, catalogId: Catalog): Promise<CategoryTip[]> => {
  const categories = await getCategoryTree(languageId, catalogId)
  return categories.filter(({ level }) => level === 1)
}

export const getLevel1CategoriesPaths = async (languageId: number, catalogId: Catalog): Promise<number[]> => {
  const categories = await getLevel1Categories(languageId, catalogId)
  return categories.map(({ id }) => id)
}

export interface UseCategoryInput {
  id: number | string
  page: number | string
}

export function useCategory(filters: ProductSearchFilter): SWRResponse<ProductSearchResult, any> {
  const { categoryId, languageId, facets } = filters

  const usp = new URLSearchParams(filters as any)

  // Create a stable key for SWR
  usp.sort()
  const qs = usp.toString()

  return useSWRImmutable<ProductSearchResult>(
    categoryId || categoryId === 0 ? `/api/category/${categoryId}${qs ? `?${qs}` : ''}` : null
  )
}
